import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Image from "../../../components/image"
import { FaChevronRight } from "react-icons/fa"
import Helmet from "react-helmet"
import ListTop3 from "../components/listTop3"

const typeSizePage = ({ data, location }) => {
	const type = data.typesMilkYaml
	const size = data.sizesMilkYaml
	const sizeStr = '' 
	const itemStr = 'ミルク'
	const unitStr = '100ml'
	const category = 'milk'
	const categoryIndex = '/milk/milk_powder_can_big_all_all'
	let description = `${type.description} ${size.description}${sizeStr} ${itemStr}${unitStr}あたりの最安値！`
	let preTitle = ``

	// allContentfulItemsを100ml当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	data.allContentfulItems.edges.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})	

	// allBrandsMilkYamlを100ml当たりの価格が安い順に並び替える
	let orderAllBrandsYaml = data.allBrandsMilkYaml.edges.map(edge => {
		const item = data.allContentfulItems.edges.find(n => {
			return n.node.typeId === type.id && n.node.sizeId === size.id && n.node.brandId === edge.node.id
		})
		if(item) {
			if(item.node.itemList.length){
				edge['per'] = item.node.itemList[0].per	
			}
		}
		return edge
	}).sort(function(a,b){
		return a.per - b.per
	})

	const cheap_item = data.allContentfulItems.edges.find(edge => {
		return edge.node.sizeId === size.id
	})
	let cnt = 0

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": itemStr,
			"item": "https://hula-la.com" + categoryIndex
		},{
			"@type": "ListItem",
			"position": 3,
			"name": type.description,
			"item": "https://hula-la.com/"+category+"/"+type.id+".html"
		},{
			"@type": "ListItem",
			"position": 4,
			"name": size.description,
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	return (
  <Layout>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				<h2 id="top10" className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{type.description} {size.description}{sizeStr} Top10</h2>
				<p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>{unitStr}あたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
				<ListTop3 typeValue={type.id} sizeValue={size.id} brandValue="all" shopValue="all" articleUrl={`https://hula-la.com/${type.id}_${size.id}.html`} articleTitle={`${type.description} ${size.description}`} />
				<h3 id="brand" className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>ブランド別</h3>
				<div className="columns is-multiline is-gapless">
					{orderAllBrandsYaml.map(edge => {
						const brand = edge.node
						const item = data.allContentfulItems.edges.find(n => {
							return n.node.brandId === brand.id
						})
						if(!item) return <></>
						const filename = `${brand.id}_${type.id}_${size.id}_${item.node.subbrandId}.jpg`
						if(!item.node.itemList.length) return <></>
						description += `${brand.description}${item.node.itemList[0].per.toFixed(1)}円 `
						preTitle += preTitle === `` ? `${item.node.itemList[0].per.toFixed(1)}円/${unitStr}` : ``
						cnt += 1
						const order = cnt
						return (
							<div className="column is-half">
								<Link to={`/${category}/${brand.id}_${type.id}_${size.id}.html`}>
									<div className="card">
										<div className="card-content" style={{padding: `0.8rem`}}>
											<div className="media">
												<div className="media-left">
													<div>
														<span>{order}</span><span style={{fontSize:`0.5rem`}}>位</span>
													</div>
													<Image filename={filename} alt={`${brand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
												</div>
												<div className="media-content">
													<div className="content">
														<p>
															<span style={{fontSize:`0.7rem`}}><strong>{brand.description}</strong></span>
															<br />
															<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/{unitStr}</span> ～</span>
															<br />
															<span className="tag" style={{fontSize:`0.6rem`}}>{type.description}</span>
														</p>
													</div>
												</div>
												<div className="media-right">
													<span className="icon" style={{height: `2.5em`}}>
														<FaChevronRight />
													</span>
												</div>
											</div>
										</div>
									</div>
								</Link>
							</div>
						)
					})}
				</div>
			</>
		<Seo title={`${type.description} ${size.description}`} description={description} canonical={location.pathname} />
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`${categoryIndex}`}>{itemStr}</Link></li>
				<li><Link to={`/${category}/${type.id}.html`}>{type.description}</Link></li>
				<li className="is-active"><Link to="" aria-current="page">{size.description}</Link></li>
			</ul>
		</nav>
  </Layout>
	)
}

export const query = graphql`
	query($type_id: String! $size_id: String!) {
		typesMilkYaml(id: { eq: $type_id}) {
			id
			description
		}
		allTypesMilkYaml {
			edges {
				node {
					id
					description
				}
			}
		}
		sizesMilkYaml(id: { eq: $size_id}) {
			id
			description
		}
		allBrandsMilkYaml {
			edges {
				node {
					id
					description
				}
			}
		}
		allContentfulItems(filter: {typeId: { eq: $type_id} sizeId: { eq: $size_id}}) {
			edges {
				node {
					brandId
					typeId
					sizeId
					subbrandId
					itemList {
						num
						shopId
						shop
						href
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
					updatedAt
				}
			}
		}
		allSubbrandsMilkYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							num	
							unit
							use
							pack_unit
						}
					}
				}
			}
		}
	}
`

export default typeSizePage
